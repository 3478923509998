:root {
  --primary: #161616;
  --secondary: #878686;
  --background: #ffffff;
  --card: #f3f3f3;
  --colored: #DF1748;
  --pagePadding: 16px;
  --colorSwitchTime: 0.4s;
}

body {
  margin: 0 !important;
  padding: 0 !important;
  font-family: 'Quicksand';
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  -webkit-user-select: none;
  user-select: none;
  background-color: var(--background);
  transition: background-color var(--colorSwitchTime) ease;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New', monospace;
}

.container {
  padding: var(--pagePadding);
  padding-top: 50px;
}

#subheading p {
  font-size: 42pt;
  font-weight: bold;
  /* color: var(--secondary); */
  line-height: 0.7;
}

#colored {
  /* color: var(--colored); */
}

#heading p {
  letter-spacing: -0.04em;
  font-size: 74pt;
  font-weight: bold;
  /* color: var(--primary); */
  line-height: 0.8;
}

#description p {
  font-size: 16pt;
  font-weight: bold;
  /* color: var(--secondary); */
}

#description {
  margin-top: 50px;
  margin-bottom: 50px;
}

#select {
  font-size: 18pt;
  font-weight: bold;
  padding: 14px 60px;
  border-radius: 26px;
}

#select:active {
  transform: scale(0.98);
  background-color: var(--colored) !important;
  transition: background-color 0.4s ease;
}

#clear {
  padding-top: 10px;
  margin-bottom: 26px;
  font-size: 14pt;
  text-align: center;
}

#clear:active {
  transform: scale(0.94);
  filter: brightness(70%);
}

#swatches {
  margin-top: 30px;
  padding-left: var(--pagePadding);
  transition: color background-color 0.4s ease;
}

#swatch-card {
}

#fullstop {
  font-family: serif !important;
}

.fullstop {
  opacity: 0;
}

#swatch-card {
  margin-bottom: 16px;
  padding: 6px;
  border-radius: 26px;
  transition: transform 0.2s ease;
}

#swatch-card:active {
  transform: scale(0.98);
}

#swatch-container {
  width: 100%;
  padding-left: var(--pagePadding);
  padding-right: var(--pagePadding);
  margin: 0 !important;
  padding: 0 !important;
}

#color-card {
  width: 60px !important;
  height: 60px;
  border-radius: 20px;
  border: solid 2px;
}

#colorText p {
  margin: 0;
  padding-right: 48px;
  width: 180px;
}

#colorName {
  font-size: 18pt;
  font-weight: bold;
  line-height: 1.1;
  text-transform: capitalize;
}

#colorHex {
  line-height: 1.3;
  font-family: monospace;
  font-size: 14pt;
}

#colorCopy {
  margin-right: 16px;
  margin-bottom: 0px;
  padding-bottom: 0px;
  line-height: 1;
}

.swatch-images div {
  margin-bottom: 20px;
}

.swatch-images {
  width: 100%;
  height: 104px;
  border-radius: 26px;
  padding: 7px;
  padding-right: 0px;
  overflow-x: scroll;
  overflow-y: hidden;
  white-space: nowrap;
  max-height: 102px;
  -ms-overflow-style: none;
  font-size: 0;
}

.swatch-images::-webkit-scrollbar {
  display: none;
}

#swatch-image:active {
  transform: scale(0.96);
}

#swatch-image {
  width: 90px;
  margin: 0px !important;
  padding: 0px !important;
  margin-right: 7px !important;
  display: inline-block;
}

#swatch-image img {
  border-radius: 19px;
  width: 100%;
  -webkit-user-select: none;
  -khtml-user-select: none;
  -moz-user-select: none;
  -o-user-select: none;
  user-select: none;
  -webkit-touch-callout: none;
}

#try {
  font-weight: bold;
  font-size: 22pt;
}

#images-cont {
  margin-top: -36px;
  margin-bottom: 50px;
}

.poof {
  opacity: 0;
}

#subheading p, #heading p #description p, #try, .swatch-images, #color-card {
  transition: color ease var(--colorSwitchTime), background-color ease var(--colorSwitchTime);
}

.copyIcon, .doneIcon {
  transition: ease 0.2s;
}

#clear, #swatch-image {
  transition: transform ease 0.1s;
}

#select {
  transition: transform ease 0.1s;
}

#swatch-card {
  transition: color ease var(--colorSwitchTime), background-color ease var(--colorSwitchTime), transform ease 0.1s;
}

.slow-select {
  transition: color ease var(--colorSwitchTime), background-color ease var(--colorSwitchTime) !important;
}
